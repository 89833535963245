import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Card, Table, Typography, Button, Modal, Form, Input, DatePicker, Tag, Select, message, Tooltip, Tabs } from 'antd';
import { FileAddOutlined, EditOutlined, DeleteOutlined, SearchOutlined, LinkOutlined, ExportOutlined } from "@ant-design/icons"
import moment from "moment"
import { StatusOptions, SumarryColumns, StatusOptionsForAdmin } from '../../helpers/enums';
import dayjs from 'dayjs';
import * as changeCase from "change-case";
import { saveAs } from 'file-saver';
import _ from "underscore";
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;

const config = {
    rules: [
        {
            type: 'object',
            required: true,
            message: 'Please select date!',
        },
    ],
};


const ArticleHistory = () => {
    const rangePickerRef = useRef(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfimation] = useState(false);
    const [showPublishedArticleLink, setShowPublishedArticleLink] = useState(false);
    const [showReviewersComment, setShowReviewersComment] = useState(false);
    const [reviewersComment, setReviewersComment] = useState("");
    const [articleUrl, setArticleUrl] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [showInfoGraphics, setShowInfoGraphics] = useState(false);
    const [isLoadingAddArticle, setIsLoadingAddArticle] = useState(false);
    const [isLoadingDeleteArticle, setIsLoadingDeleteArticle] = useState(false);
    const [isEverythingUnique, setIsEverythingUnique] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState("Long-form");
    const [activeTab, setActiveTab] = useState("All")
    const [articlesForUser, setArticlesForUser] = useState({
        isLoading: false,
        articleList: []
    });
    const [filters, setFilters] = useState({
        sort: "created"
    });
    const userDetails = JSON.parse(localStorage.getItem("auth"))
    let isCopyEditor = userDetails?.roles?.includes("copy-editor")
    let isCreative = userDetails?.roles?.includes("designer")
    let today = moment()?.format("YYYY-MM-DD");
    let tomorrow = moment().add(1, 'days').format("YYYY-MM-DD")

    let filteredArticleList = filters?.name ?
        articlesForUser?.articleList?.filter((article) => {
            let searchTerm = filters?.name?.toLowerCase();
            if (!searchTerm) return true;


            const fieldsToSearch = [
                article?.author,
                article?.headline,
                article?.reference_details,
                article?.significance_details,
                article?.storyIdea,
                article?.reviewer_comment,
            ];
            return fieldsToSearch.some((field) =>
                field?.toLowerCase()?.includes(searchTerm)
            );
        }
        )
        : articlesForUser?.articleList?.slice()
    const articlesGroupedByStatus = _.groupBy(filteredArticleList, x => x?.status);


    useEffect(() => {
        rangePickerRef.current = filters;
    }, [filters]);


    const tabList = [
        {
            key: 'All',
            label: `All (${filteredArticleList?.filter(x => x?.status !== "Edited")?.length})`,

        },
        {
            key: 'Draft',
            label: `Draft (${articlesGroupedByStatus?.Draft?.length ? articlesGroupedByStatus?.Draft?.length : 0})`,

        },
        {
            key: 'Idea Approved',
            label: `Idea Approved (${(articlesGroupedByStatus?.["WIP"]?.length ? articlesGroupedByStatus?.["WIP"]?.length : 0) + (articlesGroupedByStatus?.["Published"]?.length ? articlesGroupedByStatus?.["Published"]?.length : 0) + (articlesGroupedByStatus?.["Rework"]?.length ? articlesGroupedByStatus?.["Rework"]?.length : 0) + (articlesGroupedByStatus?.["Pending Edit"]?.length ? articlesGroupedByStatus?.["Pending Edit"]?.length : 0)})`,

        },
        {
            key: 'Story On Hold',
            label: `Story On Hold (${articlesGroupedByStatus?.["Story On Hold"]?.length ? articlesGroupedByStatus?.["Story On Hold"]?.length : 0})`,
        },
        {
            key: 'WIP',
            label: `WIP (${articlesGroupedByStatus?.["WIP"]?.length ? articlesGroupedByStatus?.["WIP"]?.length : 0})`,
        },
        {
            key: 'Published',
            label: `Published (${articlesGroupedByStatus?.["Published"]?.length ? articlesGroupedByStatus?.["Published"]?.length : 0})`,
        },
        {
            key: 'Pending Edit',
            label: `Pending Edit (${articlesGroupedByStatus?.["Pending Edit"]?.length ? articlesGroupedByStatus?.["Pending Edit"]?.length : 0})`,
        },
        {
            key: 'Rework',
            label: `Rework (${articlesGroupedByStatus?.["Rework"]?.length ? articlesGroupedByStatus?.["Rework"]?.length : 0})`,
        },
        {
            key: 'Rejected',
            label: `Rejected (${articlesGroupedByStatus?.["Rejected"]?.length ? articlesGroupedByStatus?.["Rejected"]?.length : 0})`,
        },
        {
            key: 'Edited',
            label: `Edited (${articlesGroupedByStatus?.["Edited"]?.length ? articlesGroupedByStatus?.["Edited"]?.length : 0})`,
        },
    ];

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 150
        },
        {
            title: 'Headline',
            dataIndex: 'headline',
            key: 'headline',
            width: 250
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            width: 150
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 150
        },
        {
            title: 'Infographics',
            dataIndex: 'infographics',
            key: 'infographics',
            width: 70
        },
        {
            title: 'Updated At',
            dataIndex: 'updated_at',
            key: 'updated_at',
            width: 200
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            render: (data) => {
                return (
                    <Select
                        className='no-border-select '
                        placeholder="Select status"
                        value={data?.status}
                        onChange={(value) => {
                            if (value === "Published" && !userDetails?.roles?.includes("admin")) {
                                handleShowPublishedArticleLink(value, data?._id)
                            } else if ((value === "WIP" || value === "Rejected") && userDetails?.roles?.includes("admin")) {
                                handleShowReviewersCommentModal(value, data);
                            } else {
                                handleChangeStatus(value, data?._id)
                            }
                        }}
                        style={{ width: 150, border: "none", boxShadow: "none" }}
                        disabled={userDetails?.roles?.includes("admin") ? false : (isCopyEditor || isCreative) ? true : (data?.status === "Published" || data?.status === "Rejected" || data?.status === "Draft")}
                    >
                        {
                            userDetails?.roles?.includes("admin") ? StatusOptionsForAdmin?.map((option, index) => (
                                <Option key={option.value} value={option.value}>
                                    <Tag color={option.color}>{option.label}</Tag>
                                </Option>
                            )) : StatusOptions?.map(option => (
                                <Option key={option.value} value={option.value}>
                                    <Tag color={option.color}>{option.label}</Tag>
                                </Option>
                            ))
                        }

                    </Select >
                )
            },
        },

    ];

    if (!isCreative && !isCopyEditor) {
        columns.push({
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: 100,
            render: (articleData) => {
                return articleData?.status === "Published" ? null :
                    (<Tooltip title="Edit Article">
                        <Button
                            onClick={() => {
                                handleEditArticle(articleData)
                            }}
                            style={{ backgroundColor: "rgb(251, 229, 9", color: "black" }}
                            type="primary"
                            shape="circle"
                            icon={<EditOutlined />}
                        />
                    </Tooltip>)
            }

            ,

        },)
    }
    if (userDetails?.roles?.includes("admin")) {
        columns.push({
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            width: 100,
            render: (articleData) => {
                return articleData?.status === "Published" && !userDetails?.roles?.includes("admin") ? null : (<Tooltip title="Delete Article">
                    <Button
                        onClick={() => {
                            handleShowDeleteConfirmation(articleData)
                        }}
                        style={{ backgroundColor: "rgb(251, 229, 9", color: "black" }}
                        type="primary"
                        shape="circle"
                        icon={<DeleteOutlined />}
                    />
                </Tooltip>)
            },
        },)
    }

    //delete article
    const handleShowDeleteConfirmation = (articleData) => {
        setShowDeleteConfimation(true)
        setIsEdit(articleData?._id)
    }
    const handleHideDeleteConfirmation = () => {
        setShowDeleteConfimation(false)
        setIsEdit(false);
    }

    //published article link
    const handleShowPublishedArticleLink = (value, id) => {
        setShowPublishedArticleLink(true);
        setIsEdit(id);
    }
    const handleHidePublishedArticleLink = () => {
        setShowPublishedArticleLink(false);
        setArticleUrl("")
        setIsEdit("");
    }

    //Reviewers Comments
    const handleShowReviewersCommentModal = (value, item) => {
        setShowReviewersComment(true)
        setIsEdit({
            item,
            value
        });
    }
    const handleHideReviewersCommentModal = () => {
        setShowReviewersComment(false)
        setReviewersComment("");
        setIsEdit("");
    }

    const onDeleteConfirm = async () => {
        setIsLoadingDeleteArticle(true)
        try {
            const response = await fetch(`${apiUrl}/v1/editorial/storyIdea/${isEdit}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails?.token}`
                },
            });
            const result = await response.json();
            if (result?.data?._id) {
                handleHideDeleteConfirmation();
                if (filters?.dateRange?.[0] && filters?.dateRange?.[1]) {
                    getAllArticlesForTheUser(dayjs(filters?.dateRange?.[0], "YYYY-MM-DD").format("YYYY-MM-DD"), dayjs(filters?.dateRange?.[1], "YYYY-MM-DD").format("YYYY-MM-DD"), filters?.sort);
                } else {
                    getAllArticlesForTheUser(today, tomorrow, filters?.sort);
                }
            }
            setIsLoadingDeleteArticle(false);
            setSelectedCategory("Long-form")
        } catch (error) {
            setIsLoadingDeleteArticle(false)
            messageHandler("Something went wrongs!!!", "error");
            return error;
        }
    }

    const handleChangeStatus = async (status, id) => {
        try {
            const response = await fetch(`${apiUrl}/v1/editorial/storyIdea/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails?.token}`
                },
                body: JSON.stringify({
                    status: status,
                }),
            });
            const result = await response.json();
            if (result?.data?._id) {
                messageHandler("Status changed Successfully", "success");
                setSelectedCategory("Long-form")
                if (filters?.dateRange?.[0] && filters?.dateRange?.[1]) {
                    getAllArticlesForTheUser(dayjs(filters?.dateRange?.[0], "YYYY-MM-DD").format("YYYY-MM-DD"), dayjs(filters?.dateRange?.[1], "YYYY-MM-DD").format("YYYY-MM-DD"), filters?.sort);
                } else {
                    getAllArticlesForTheUser(today, tomorrow, filters?.sort);
                }
            }

        } catch (err) {
            console.log(err, 'err');
        }
    }

    const handlePublish = async () => {
        try {
            const response = await fetch(`${apiUrl}/v1/editorial/storyIdea/${isEdit}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails?.token}`
                },
                body: JSON.stringify({
                    status: "Published",
                    article_link: articleUrl ? articleUrl : ""
                }),
            });
            const result = await response.json();
            if (result?.data?._id) {
                setSelectedCategory("Long-form");
                handleHidePublishedArticleLink()
                messageHandler("Status changed Successfully", "success");
                if (filters?.dateRange?.[0] && filters?.dateRange?.[1]) {
                    getAllArticlesForTheUser(dayjs(filters?.dateRange?.[0], "YYYY-MM-DD").format("YYYY-MM-DD"), dayjs(filters?.dateRange?.[1], "YYYY-MM-DD").format("YYYY-MM-DD"), filters?.sort);
                } else {
                    getAllArticlesForTheUser(today, tomorrow, filters?.sort);
                }
            }

        } catch (err) {
            console.log(err, 'err');
        }
    }
    const handleApproveOrReject = async () => {
        setIsLoadingDeleteArticle(true)
        try {
            const response = await fetch(`${apiUrl}/v1/editorial/storyIdea/${isEdit?.item?._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails?.token}`
                },
                body: JSON.stringify({
                    status: isEdit?.value,
                    reviewer_comment: reviewersComment ? reviewersComment : ""
                }),
            });
            const result = await response.json();
            if (result?.data?._id) {
                setIsLoadingDeleteArticle(false);
                handleHideReviewersCommentModal();
                setSelectedCategory("Long-form")
                messageHandler("Status changed Successfully", "success");
                if (filters?.dateRange?.[0] && filters?.dateRange?.[1]) {
                    getAllArticlesForTheUser(dayjs(filters?.dateRange?.[0], "YYYY-MM-DD").format("YYYY-MM-DD"), dayjs(filters?.dateRange?.[1], "YYYY-MM-DD").format("YYYY-MM-DD"), filters?.sort);
                } else {
                    getAllArticlesForTheUser(today, tomorrow, filters?.sort);
                }
            }

        } catch (err) {
            setIsLoadingDeleteArticle(false)
            console.log(err, 'err');
        }
    }

    const handleEditArticle = (articleData) => {
        form.setFieldsValue({
            storyIdea: articleData?.storyIdea,
            headline: articleData?.headline,
            category: articleData?.category,
            significance_details: articleData?.significance_details,
            reference_details: articleData?.reference_details,
            date: dayjs(articleData?.date, "YYYY-MM-DD"),
            infographicsIdea: articleData?.infographicsIdea ? articleData?.infographicsIdea : "",
            comments: articleData?.comments ? articleData?.comments : "",
            status: articleData?.status,
            infographics: articleData?.infographics ? "yes" : "no",
        });
        setSelectedCategory(articleData?.category)
        setShowInfoGraphics(articleData?.infographics);
        setIsModalOpen(true);
        setIsEdit(articleData?._id);
    }


    const messageHandler = useCallback((title, status) => {
        messageApi.open({
            type: status,
            content: title,
            duration: 5,
        });
    }, [messageApi]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        // setIsModalOpen(false);
        form.submit()
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setIsEdit(false);
        handleClearForm();
        setIsEverythingUnique(true);
        setSelectedCategory("Long-form")
    };

    const getArticlesList = () => {
        let finalList = articlesForUser?.articleList?.slice();

        if (activeTab !== "All" && activeTab === "Idea Approved") {
            finalList = finalList?.filter((x) => x?.status === "WIP" || x?.status === "Published" || x?.status === "Pending Edit" || x?.status === "Rework")
        } else if (activeTab !== "All") {
            finalList = finalList?.filter((x) => x?.status === activeTab)
        } else if (activeTab === "All") {
            finalList = finalList?.filter(x => x?.status !== "Edited");
        }

        //handle filters
        if (filters?.name) {
            finalList = finalList?.filter((article) => {
                let searchTerm = filters?.name?.toLowerCase();

                if (!searchTerm) return true;


                const fieldsToSearch = [
                    article?.author,
                    article?.headline,
                    article?.reference_details,
                    article?.significance_details,
                    article?.storyIdea,
                    article?.reviewer_comment,
                ];
                return fieldsToSearch.some((field) =>
                    field?.toLowerCase()?.includes(searchTerm)
                );
            });


        }
        return finalList;
    }

    const articlesByName = _.groupBy(getArticlesList(), x => x?.author);
    const summaryData = Object.keys(articlesByName)?.map((author, index) => {
        let fullName = author ? author?.split('@')?.[0]?.split(".") : ["John", "Doe"];
        let finalName = !fullName?.[1] ? changeCase.capitalCase(fullName?.[0]) : changeCase.capitalCase(fullName?.[0]) + " " + changeCase.capitalCase(fullName?.[1]);
        let groupedByStatuses = _.groupBy(articlesByName?.[author], x => x?.status)
        return (
            {
                key: index,
                "name": finalName && finalName !== "Undefined" ? finalName : "John Doe",
                "Draft": groupedByStatuses?.["Draft"]?.length ? groupedByStatuses?.["Draft"]?.length : 0,
                "Idea Approved": ((groupedByStatuses?.["WIP"]?.length ? groupedByStatuses?.["WIP"]?.length : 0) + (groupedByStatuses?.["Published"]?.length ? groupedByStatuses?.["Published"]?.length : 0) + (groupedByStatuses?.["Rework"]?.length ? groupedByStatuses?.["Rework"]?.length : 0) + (groupedByStatuses?.["Pending Edit"]?.length ? groupedByStatuses?.["Pending Edit"]?.length : 0)),
                "Story On Hold": groupedByStatuses?.["Story On Hold"]?.length ? groupedByStatuses?.["Story On Hold"]?.length : 0,
                "WIP": groupedByStatuses?.["WIP"]?.length ? groupedByStatuses?.["WIP"]?.length : 0,
                "Published": groupedByStatuses?.["Published"]?.length ? groupedByStatuses?.["Published"]?.length : 0,
                "Rejected": groupedByStatuses?.["Rejected"]?.length ? groupedByStatuses?.["Rejected"]?.length : 0,
                "Total": articlesByName?.[author]?.length,
            }
        )
    });
    //pranay
    const dataNew = getArticlesList()?.map((item, index) => {
        let fullName = item?.author ? item?.author?.split('@')?.[0]?.split(".") : ["John", "Doe"];
        let finalName = !fullName?.[1] ? changeCase.capitalCase(fullName?.[0]) : changeCase.capitalCase(fullName?.[0]) + " " + changeCase.capitalCase(fullName?.[1]);
        return (
            {
                key: index,
                name: finalName,
                headline: item?.headline,
                category: item?.category,
                significance_details: item?.significance_details,
                reference_details: item?.reference_details,
                date: moment(item?.date).format("DD-MM-YYYY"),
                infographics: item?.infographics ? "Yes" : "No",
                status: item,
                infographicsIdea: item?.infographicsIdea,
                updated_at: moment(item?.updatedAt).format("DD-MM-YYYY hh:mm A"),
                comments: item?.comments,
                storyIdea: item?.storyIdea,
                edit: item,
                delete: item,
                reviewer_comment: item?.reviewer_comment,

            }
        )
    });

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Makes the scroll smooth
        });
    };

    //form states
    const onFinish = async (values) => {
        let finalPayload = Object.assign({}, values, {
            date: moment(new Date(values?.date))?.format("YYYY-MM-DD"),
            author: userDetails?.email,
            infographics: values?.infographics === "yes" ? true : false,
        })

        if (userDetails?.roles?.includes("admin")) {
            delete finalPayload["author"]
        }

        const hasUniqueValues = new Set(Object.values(finalPayload)).size === Object.keys(finalPayload).length;
        if (!hasUniqueValues) {
            setIsEverythingUnique(false);
            return;
        } else {
            setIsEverythingUnique(true);
        }


        if (selectedCategory === "Edited Article") {
            finalPayload["status"] = "Edited"
        } else if (selectedCategory === "News" || selectedCategory === "Video") {
            finalPayload["status"] = "Published"
        } else {
            finalPayload["status"] = "Draft";
        }

        try {
            setIsLoadingAddArticle(true)
            if (isEdit) {
                const response = await fetch(`${apiUrl}/v1/editorial/storyIdea/${isEdit}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${userDetails?.token}`
                    },
                    body: JSON.stringify(finalPayload),
                });
                const result = await response.json();
                if (result?.data?._id) {
                    setIsLoadingAddArticle(false)
                    setIsModalOpen(false)
                    handleClearForm();
                    setSelectedCategory("Long-form")
                    setIsEdit(true);
                    if (filters?.dateRange?.[0] && filters?.dateRange?.[1]) {
                        getAllArticlesForTheUser(dayjs(filters?.dateRange?.[0], "YYYY-MM-DD").format("YYYY-MM-DD"), dayjs(filters?.dateRange?.[1], "YYYY-MM-DD").format("YYYY-MM-DD"), filters?.sort);
                    } else {
                        getAllArticlesForTheUser(today, tomorrow, filters?.sort);
                    }
                    messageHandler("Article is updated!!", "success");
                }
            } else {

                const response = await fetch(`${apiUrl}/v1/editorial/storyIdea`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${userDetails?.token}`
                    },
                    body: JSON.stringify(finalPayload),
                });
                const result = await response.json();

                if (result?.data?._id) {
                    setIsLoadingAddArticle(false)
                    setIsModalOpen(false)
                    setFilters(Object.assign({}, filters, {
                        dateRange: []
                    }))
                    handleClearForm();
                    setIsEdit(true);
                    setSelectedCategory("Long-form")
                    messageHandler("Article is added!!", "success");
                    if (filters?.dateRange?.[0] && filters?.dateRange?.[1]) {
                        getAllArticlesForTheUser(dayjs(filters?.dateRange?.[0], "YYYY-MM-DD").format("YYYY-MM-DD"), dayjs(filters?.dateRange?.[1], "YYYY-MM-DD").format("YYYY-MM-DD"), filters?.sort);
                    } else {
                        getAllArticlesForTheUser(today, tomorrow, filters?.sort);
                    }
                }
            }
            setIsLoadingAddArticle(false);
        } catch (error) {
            setIsLoadingAddArticle(false)
            messageHandler("Something went wrong!!!", "error");
            return error;
        }
    };



    const getAllArticlesForTheUser = useCallback(async (startDate, endDate, type) => {
        setArticlesForUser({
            isLoading: true,
            articleList: []
        });

        try {
            const response = await fetch(`${apiUrl}/v1/editorial/allStoryIdeas?${type === "created" ? "startDate" : "startUpdatedDate"}=${startDate}&${type === "created" ? "endDate" : "endUpdatedDate"}=${endDate}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails?.token}`
                },
            });
            const result = await response.json();
            if (result?.data?.length > 0) {
                setArticlesForUser({
                    isLoading: false,
                    articleList: result.data
                });
            } else {
                setArticlesForUser((prev) => ({
                    ...prev,
                    isLoading: false
                }));
            }

        } catch (error) {
            setArticlesForUser({
                isLoading: false,
                articleList: []
            });
            messageHandler("Something went wrong in fetching articles!!!", "error");
            return error;
        }
    }, [userDetails?.token, messageHandler, apiUrl]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (rangePickerRef.current?.dateRange?.[0] && rangePickerRef.current?.dateRange?.[1]) {
                getAllArticlesForTheUser(dayjs(rangePickerRef.current?.dateRange?.[0], "YYYY-MM-DD").format("YYYY-MM-DD"), dayjs(rangePickerRef.current?.dateRange?.[1], "YYYY-MM-DD").format("YYYY-MM-DD"), filters?.sort);
            } else {
                getAllArticlesForTheUser(today, tomorrow, filters?.sort);
            }
        }, 10 * 60 * 1000);

        return () => clearInterval(intervalId);
    }, [filters?.sort, today, tomorrow, getAllArticlesForTheUser]);

    useEffect(() => {
        let todaysDate = moment()?.format("YYYY-MM-DD")
        let tomorrowsDate = moment().add(1, 'days').format("YYYY-MM-DD")
        getAllArticlesForTheUser(todaysDate, tomorrowsDate, "created");
    }, [getAllArticlesForTheUser])

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    const handleClearForm = () => {
        form.setFieldsValue({
            storyIdea: "",
            headline: "",
            category: "",
            significance_details: "",
            reference_details: "",
            date: "",
            infographicsIdea: "",
            comments: "",
            status: "",
            infographics: "",
        })
        setShowInfoGraphics(false)
    }

    const handleChangeFilters = (type, value) => {
        setFilters(Object.assign({}, filters, {
            [type]: value
        }))
    }

    const handleExportCSV = () => {
        const headers = [
            "Name",
            "Headline",
            "Category",
            "Significance Details",
            "Reference Details",
            "Date",
            "Infographics",
            "Status",
            "Infographics Idea",
            "Updated At",
            "Comments",
            "Story Idea",
            "Reviewer Comment",
        ];
        const csvData = dataNew?.map((item) => [
            item.name ? item.name : "",
            item.headline ? item.headline : "",
            item.category ? item.category : "",
            item.significance_details ? item.significance_details : "",
            item.reference_details ? item.reference_details : "",
            item.date ? item.date : "",
            item.infographics ? item.infographics : " ",
            item.status?.status || "", // Add appropriate property for 'status'
            item.infographicsIdea ? item.infographicsIdea : " ",
            item.updated_at ? item.updated_at : "",
            item.comments ? item.comments : "",
            item.storyIdea ? item.storyIdea : "",
            item.reviewer_comment ? item.reviewer_comment : "",
        ]);
        const csvContent =
            [headers, ...csvData]
                .map((row) => row.map((value) => `"${value || ""}"`).join(","))
                .join("\n");

        saveAs(new Blob([csvContent], { type: "text/csv;charset=utf-8;" }), "articles.csv");

    }

    const validateWordCount = (_, value) => {
        const words = value ? value.trim().split(/\s+/).length : 0;
        if (words < 30) {
            return Promise.reject(new Error('The idea must have at least 30 words!'));
        } else if (words > 50) {
            return Promise.reject(new Error('The idea must have no more than 50 words!'));
        } else {
            return Promise.resolve();
        }
    };



    return (
        <div style={{ maxWidth: "1440px", margin: "0 auto" }}>
            {contextHolder}
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "15px" }}>
                <Title level={4}>Welcome {userDetails?.email?.split("@")?.[0]?.split(".")?.[0] ? changeCase.capitalCase(userDetails?.email?.split("@")?.[0]?.split(".")?.[0]) : ""}</Title>
                {!isCreative && <Button
                    onClick={() => {
                        showModal();
                        setIsEdit(false);
                        handleClearForm();
                        form.setFieldValue("date", dayjs(moment()))
                    }}
                    type="primary"
                    icon={<FileAddOutlined />}
                    style={{ backgroundColor: "#000" }}
                >
                    Add New
                </Button>}
            </div>
            <Card>
                <Tabs
                    defaultActiveKey="1"
                    items={tabList}
                    onChange={(val) => {
                        setActiveTab(val)
                    }}
                />

                <div style={{ display: 'flex', flexDirection: "row", marginBottom: "25px", justifyContent: "flex-end", alignItems: "center" }}>
                    {userDetails?.roles?.includes("admin") && <Input style={{ width: 250, marginRight: "15px" }} onChange={(e) => {
                        handleChangeFilters("name", e?.target?.value)
                    }} size="large" placeholder="Search" prefix={<SearchOutlined />} />}
                    <Select
                        style={{ width: 250, marginRight: "15px" }}
                        placeholder="Select Filter"
                        value={filters?.sort}
                        onChange={(value) => {
                            setFilters(Object.assign({}, filters, {
                                sort: value
                            }))
                            if (filters?.dateRange?.[0] && filters?.dateRange?.[1]) {
                                getAllArticlesForTheUser(dayjs(filters?.dateRange?.[0], "YYYY-MM-DD").format("YYYY-MM-DD"), dayjs(filters?.dateRange?.[1], "YYYY-MM-DD").format("YYYY-MM-DD"), value);
                            } else {
                                getAllArticlesForTheUser(today, tomorrow, value);
                            }
                        }}
                    >
                        <Option value="created">Article Date</Option>
                        <Option value="updated">Last Updated (Published)</Option>
                    </Select>
                    <RangePicker
                        style={{ width: 250 }}
                        onChange={(date, dateString) => {
                            if (dateString?.[0] && dateString?.[1]) {
                                setFilters(Object.assign({}, filters, {
                                    dateRange: date
                                }))
                                getAllArticlesForTheUser(dateString?.[0], dateString?.[1], filters?.sort);
                            } else {
                                setFilters(Object.assign({}, filters, {
                                    dateRange: [dayjs(moment()), dayjs(moment().add(1, 'days'))]
                                }))
                                getAllArticlesForTheUser(today, tomorrow, filters?.sort)
                            }
                        }}
                        value={filters?.dateRange}
                        defaultValue={[dayjs(moment()), dayjs(moment().add(1, 'days'))]}
                    />

                    {userDetails?.roles?.includes("admin") && <Button
                        onClick={() => {
                            handleExportCSV()
                        }}
                        type="primary"
                        icon={<ExportOutlined />}
                        style={{ backgroundColor: "#000", marginLeft: "15px" }}
                    >
                        Export CSV
                    </Button>}
                </div>
                <Table
                    columns={columns}
                    onChange={() => {
                        scrollToTop()
                    }}
                    expandable={{
                        expandedRowRender: (record, i) => {
                            return (
                                <div style={{ paddingLeft: "15px", paddingRight: "15px", display: "flex", flexDirection: "column", maxWidth: "60%", }}>
                                    {record?.storyIdea &&
                                        <>
                                            <h4>Story Idea</h4>
                                            <p
                                                style={{
                                                    marginBottom: "15px"
                                                }}
                                            >
                                                {record?.storyIdea}
                                            </p>
                                        </>
                                    }
                                    {record?.significance_details &&
                                        <>
                                            <h4>Significance</h4>
                                            <p
                                                style={{
                                                    marginBottom: "15px"
                                                }}
                                            >
                                                {record?.significance_details}
                                            </p>
                                        </>
                                    }
                                    {record?.reference_details &&
                                        <>
                                            <h4>Reference</h4>
                                            <p
                                                style={{
                                                    marginBottom: "15px"
                                                }}
                                            >
                                                {record?.reference_details}
                                            </p>
                                        </>
                                    }
                                    {record?.infographics === "Yes" &&
                                        <>
                                            <h4>Infographics Idea</h4>
                                            <p
                                                style={{
                                                    marginBottom: "15px"
                                                }}
                                            >
                                                {record?.infographicsIdea}
                                            </p>
                                        </>
                                    }
                                    {record?.reviewer_comment &&
                                        <>
                                            <h4>Reviewer's Comments</h4>
                                            <p
                                                style={{
                                                    marginBottom: "15px"
                                                }}
                                            >
                                                {record?.reviewer_comment}
                                            </p>
                                        </>
                                    }
                                    {record?.comments &&
                                        <>
                                            <h4>Comment</h4>
                                            <p
                                                style={{
                                                    marginBottom: "15px"
                                                }}
                                            >
                                                {record?.comments}
                                            </p>
                                        </>
                                    }
                                    {record?.status?.status === "Published" && record?.article_link &&
                                        <>
                                            <h4>Article URL</h4>
                                            <p
                                                style={{
                                                    marginBottom: "15px"
                                                }}
                                            >
                                                {record?.comments}
                                            </p>
                                            <Tooltip title="Article">
                                                <Button style={{ marginBottom: "10px" }} shape="circle" icon={<LinkOutlined />} onClick={() => {
                                                    window.open(record?.article_link, "_blank")
                                                }} />
                                            </Tooltip>
                                        </>
                                    }

                                    <>
                                        <h4>Created On</h4>
                                        <p
                                        >
                                            {record?.date}
                                        </p>
                                    </>

                                </div>
                            )
                        },
                    }}
                    dataSource={dataNew}
                />
            </Card>
            <Modal
                width={700}
                title={"Story Board"}
                open={isModalOpen}
                onOk={handleOk}
                confirmLoading={isLoadingAddArticle}
                onCancel={handleCancel}>
                <Form
                    form={form}
                    name="basic"
                    labelCol={{
                        span: 5,
                    }}
                    wrapperCol={{
                        span: 20,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"

                >
                    <Form.Item
                        name="category"
                        label="Story Type"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select a story category"
                            onChange={(value) => {
                                setSelectedCategory(value);
                            }}
                            allowClear
                        >
                            <Option value="News">News</Option>
                            <Option value="Daily Narrative">Daily Narrative</Option>
                            <Option value="Long-form">Long-form</Option>
                            <Option value="Newsletter">Newsletter</Option>
                            <Option value="Brand content">Brand content</Option>
                            <Option value="Edited Article">Edited Article</Option>
                            <Option value="Video">Video</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Headline"
                        name="headline"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your Headline!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    {["Daily Narrative", "Long-form", "Brand content"]?.includes(selectedCategory) && <Form.Item
                        label="Story Idea"
                        name="storyIdea"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your Idea!',
                            },
                            {
                                validator: validateWordCount, // Custom validator for word count
                            },
                        ]}
                    >
                        <TextArea rows={4} placeholder="Explain the story idea in 30 to 50 words." />
                    </Form.Item>}
                    <Form.Item name="date" label="Date" {...config}>
                        <DatePicker format="YYYY-MM-DD" />
                    </Form.Item>
                    {["Daily Narrative", "Long-form"]?.includes(selectedCategory) && <Form.Item
                        label="Significance"
                        name="significance_details"
                        rules={[
                            {
                                required: true,
                                message: `Please enter the significance`,
                            },
                        ]}
                    >
                        <TextArea rows={2} placeholder={`What's new and Why does the story matter now ? Explain the significant of the story.`} />
                    </Form.Item>}
                    {["Daily Narrative", "Long-form"]?.includes(selectedCategory) && <Form.Item
                        label="Reference"
                        name="reference_details"
                        rules={[
                            {
                                required: true,
                                message: `Please enter the significance`,
                            },
                        ]}
                    >
                        <TextArea rows={2} placeholder={`Has any of this appeared elsewhere? If yes, please attach reference links of published articles.`} />
                    </Form.Item>}

                    {["Daily Narrative", "Long-form", "Brand content"]?.includes(selectedCategory) && <Form.Item
                        name="infographics"
                        label="Infographics"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select a option and change input text above"
                            onChange={(e) => {
                                if (e === "yes") {
                                    setShowInfoGraphics(true)
                                } else {
                                    setShowInfoGraphics(false)
                                }
                            }}
                            allowClear
                        >
                            <Option value="yes">Yes</Option>
                            <Option value="no">No</Option>
                        </Select>
                    </Form.Item>}

                    {showInfoGraphics && <Form.Item
                        label="Infographics Idea"
                        name="infographicsIdea"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter infographic ideas!',
                            },
                        ]}
                    >
                        <TextArea rows={2} placeholder="" />
                    </Form.Item>}
                    <Form.Item
                        label="Comments"
                        name="comments"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <TextArea rows={2} placeholder="" />
                    </Form.Item>
                </Form>
                {!isEverythingUnique && <p style={{ color: "red", textAlign: "center", padding: 0, margin: 0 }}>No two fields can contain the same value.</p>}
            </Modal >
            <Modal
                title="Confirmation"
                open={showDeleteConfirmation}
                onOk={onDeleteConfirm}
                onCancel={handleHideDeleteConfirmation}
                okText="Delete"
                cancelText="Cancel"
                confirmLoading={isLoadingDeleteArticle}
            >
                Are you sure you want to delete this Article?
            </Modal>

            <Modal
                title="Published URL"
                open={showPublishedArticleLink}
                onOk={handlePublish}
                onCancel={handleHidePublishedArticleLink}
                okText="Confirm"
                cancelText="Cancel"
                confirmLoading={isLoadingDeleteArticle}
                okButtonProps={{ disabled: articleUrl ? false : true }}
            >
                <Input
                    onChange={(e) => {
                        setArticleUrl(e?.target?.value)
                    }}
                    placeholder="Enter the URL for your Article."
                />
            </Modal>
            <Modal
                title="Reviewer's Comment"
                open={showReviewersComment}
                onOk={handleApproveOrReject}
                onCancel={handleHideReviewersCommentModal}
                okText="Confirm"
                cancelText="Cancel"
                confirmLoading={isLoadingDeleteArticle}
                okButtonProps={{ disabled: reviewersComment ? false : true }}
            >
                <Input
                    onChange={(e) => {
                        setReviewersComment(e?.target?.value)
                    }}
                    placeholder="Provide your review comments"
                />
            </Modal>

            {
                userDetails?.roles?.includes("admin") &&
                <>
                    <Title style={{ marginTop: "50px" }} level={4}>Summary</Title>
                    <Card >
                        <Table
                            columns={SumarryColumns}
                            dataSource={summaryData}
                        />
                    </Card>
                </>
            }
        </div >

    )
}

export default ArticleHistory